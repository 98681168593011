:root {
  color-scheme: light dark;
}

body {
  overflow-x: hidden;
}

a[href]:not(.MuiLink-root),
a[href]:-webkit-any-link:not(.MuiLink-root) {
  color: inherit;
  text-decoration: none;

  &:hover > {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fa255e;
    }
  }
}

div[role="presentation"] {
  &,
  & > * {
    &:focus {
      outline: none;
    }
  }
}

ul {
  padding-left: 24px;

  li {
    padding-left: 4px;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(250, 37, 94, 0.1);
}

#root {
  height: 100%;
}

::selection {
  background: rgba(250, 37, 94, 0.5);
}
